import axios from "axios";
import { storage } from "../redux/local_storage";
import { OpResult } from "../helper/operation_result";
import util from "../helper/util";
import { confirmAlert } from "react-confirm-alert";
import { Button } from "react-bootstrap";

class BaseApiRequest {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.token = undefined;
    this.config = undefined;
    //init token
    this.init();
  }

  init() {
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) {
      if (store.token) {
        this.setConfig(store.token);
      }
    }
  }

  setConfig(token) {
    this.token = token;
    const hashedToken = util.jwtHashed(token);
    this.config = {
      headers: { authorization: hashedToken },
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
      validateStatus: function () {
        return true;
      },
    };
  }

  async resetLocalStorageAndRedirectToLoginPage() {
    storage.delete(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui">
            <h3>Perhatian</h3>
            <h5>{"Sesi Habis, Silahkan masuk kembali!"}</h5>
            <div className="text-center mt-3">
              <Button
                block
                variant="link"
                onClick={() => {
                  localStorage.clear();
                  window.location = "/login";
                }}
              >
                Keluar
              </Button>
            </div>
          </div>
        );
      },
      onClickOutside: () => (window.location.href = "/login"),
    });
  }

  async get(uri) {
    try {
      this.init();
      let response = await axios.get(this.endpoint + uri, this.config);

      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401) {
          this.resetLocalStorageAndRedirectToLoginPage();
        }
        let resp = response.data
          ? response.data
          : OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log("[API_GET]", error.response);
      return OpResult.failed(`request failed`);
    }
  }

  async post(route, req_body) {
    try {
      this.init();
      axios.defaults.validateStatus = function () {
        return true;
      };
      let response = await axios.post(
        this.endpoint + route,
        req_body,
        this.config
      );
      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401) {
          this.resetLocalStorageAndRedirectToLoginPage();
        }
        let resp = response.data
          ? response.data
          : OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log("[API_POST] ", error.response);
      return OpResult.failed(`request failed`);
    }
  }
}

export { BaseApiRequest };
